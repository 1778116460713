export const PFCurrentStage4Side2 = [
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 4.22,
    rank: 1,
    avg_round: 39169,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 3.63,
    rank: 2,
    avg_round: 38481,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 3.58,
    rank: 3,
    avg_round: 38486,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 3.22,
    rank: 4,
    avg_round: 37841,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 3.13,
    rank: 5,
    avg_round: 39068,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 2.55,
    rank: 6,
    avg_round: 38123,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 2.35,
    rank: 7,
    avg_round: 24360,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 2.08,
    rank: 8,
    avg_round: 39230,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 1.99,
    rank: 9,
    avg_round: 36146,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 1.65,
    rank: 10,
    avg_round: 38345,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 1.6,
    rank: 11,
    avg_round: 39859,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 1.43,
    rank: 12,
    avg_round: 35580,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 1.33,
    rank: 13,
    avg_round: 38570,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 1.32,
    rank: 14,
    avg_round: 37922,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 1.3,
    rank: 15,
    avg_round: 38581,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 1.08,
    rank: 16,
    avg_round: 38346,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 1.08,
    rank: 16,
    avg_round: 37431,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.97,
    rank: 18,
    avg_round: 37791,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.93,
    rank: 19,
    avg_round: 39365,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.91,
    rank: 20,
    avg_round: 29212,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.89,
    rank: 21,
    avg_round: 35944,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.85,
    rank: 22,
    avg_round: 37685,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.84,
    rank: 23,
    avg_round: 35431,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.82,
    rank: 24,
    avg_round: 37750,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.81,
    rank: 25,
    avg_round: 38198,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.73,
    rank: 26,
    avg_round: 39249,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.7,
    rank: 27,
    avg_round: 34822,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.69,
    rank: 28,
    avg_round: 27202,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.6,
    rank: 29,
    avg_round: 32836,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.58,
    rank: 30,
    avg_round: 37090,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.53,
    rank: 31,
    avg_round: 25541,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.51,
    rank: 32,
    avg_round: 33818,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.51,
    rank: 32,
    avg_round: 38310,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.5,
    rank: 34,
    avg_round: 34826,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.5,
    rank: 34,
    avg_round: 37184,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.5,
    rank: 34,
    avg_round: 38040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.49,
    rank: 37,
    avg_round: 35439,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.49,
    rank: 37,
    avg_round: 35507,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.48,
    rank: 39,
    avg_round: 34421,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.46,
    rank: 40,
    avg_round: 37013,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.45,
    rank: 41,
    avg_round: 22920,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.41,
    rank: 42,
    avg_round: 28992,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.4,
    rank: 43,
    avg_round: 34477,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.4,
    rank: 43,
    avg_round: 24055,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.39,
    rank: 45,
    avg_round: 39760,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.38,
    rank: 46,
    avg_round: 37520,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.38,
    rank: 46,
    avg_round: 38649,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.37,
    rank: 48,
    avg_round: 34290,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.36,
    rank: 49,
    avg_round: 34022,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.34,
    rank: 50,
    avg_round: 25851,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.34,
    rank: 50,
    avg_round: 36704,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.34,
    rank: 50,
    avg_round: 22293,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.33,
    rank: 53,
    avg_round: 24837,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.33,
    rank: 53,
    avg_round: 34844,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.32,
    rank: 55,
    avg_round: 38883,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.32,
    rank: 55,
    avg_round: 36680,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.32,
    rank: 55,
    avg_round: 25520,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.32,
    rank: 55,
    avg_round: 26939,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.32,
    rank: 55,
    avg_round: 36303,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.31,
    rank: 60,
    avg_round: 35952,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.3,
    rank: 61,
    avg_round: 21120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.3,
    rank: 61,
    avg_round: 34773,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.29,
    rank: 63,
    avg_round: 24205,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.27,
    rank: 64,
    avg_round: 38552,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.27,
    rank: 64,
    avg_round: 33274,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.26,
    rank: 66,
    avg_round: 35668,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.26,
    rank: 66,
    avg_round: 24737,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.25,
    rank: 68,
    avg_round: 34865,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.25,
    rank: 68,
    avg_round: 39148,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.25,
    rank: 68,
    avg_round: 38920,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.25,
    rank: 68,
    avg_round: 24600,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.25,
    rank: 68,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.24,
    rank: 73,
    avg_round: 30091,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.24,
    rank: 73,
    avg_round: 34960,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.23,
    rank: 75,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.23,
    rank: 75,
    avg_round: 32285,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.23,
    rank: 75,
    avg_round: 34517,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.23,
    rank: 75,
    avg_round: 27555,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.23,
    rank: 75,
    avg_round: 25358,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.23,
    rank: 75,
    avg_round: 34707,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.23,
    rank: 75,
    avg_round: 36307,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.22,
    rank: 82,
    avg_round: 35400,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.21,
    rank: 83,
    avg_round: 33514,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.21,
    rank: 83,
    avg_round: 37630,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.21,
    rank: 83,
    avg_round: 34730,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.21,
    rank: 83,
    avg_round: 32589,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.2,
    rank: 87,
    avg_round: 27526,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.2,
    rank: 87,
    avg_round: 34477,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.2,
    rank: 87,
    avg_round: 31503,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.2,
    rank: 87,
    avg_round: 27783,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.19,
    rank: 91,
    avg_round: 32387,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.19,
    rank: 91,
    avg_round: 35010,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.19,
    rank: 91,
    avg_round: 31080,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.19,
    rank: 91,
    avg_round: 24623,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.19,
    rank: 91,
    avg_round: 24356,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'lingsha',
    app_rate: 0.19,
    rank: 91,
    avg_round: 36207,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.19,
    rank: 91,
    avg_round: 37267,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.18,
    rank: 98,
    avg_round: 34520,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.18,
    rank: 98,
    avg_round: 29295,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.17,
    rank: 100,
    avg_round: 35013,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.17,
    rank: 100,
    avg_round: 30929,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.17,
    rank: 100,
    avg_round: 30835,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.17,
    rank: 100,
    avg_round: 31222,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.17,
    rank: 100,
    avg_round: 33075,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.17,
    rank: 100,
    avg_round: 24450,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'lingsha',
    app_rate: 0.16,
    rank: 106,
    avg_round: 30231,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.16,
    rank: 106,
    avg_round: 34520,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'ruan-mei',
    app_rate: 0.16,
    rank: 106,
    avg_round: 30604,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 106,
    avg_round: 35720,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.16,
    rank: 106,
    avg_round: 26070,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 111,
    avg_round: 29047,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 111,
    avg_round: 35267,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 111,
    avg_round: 38350,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 111,
    avg_round: 24996,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.15,
    rank: 111,
    avg_round: 27320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 111,
    avg_round: 35920,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 111,
    avg_round: 33509,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.14,
    rank: 118,
    avg_round: 29445,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 118,
    avg_round: 35510,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.14,
    rank: 118,
    avg_round: 32348,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 118,
    avg_round: 31453,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.14,
    rank: 118,
    avg_round: 33114,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 118,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.14,
    rank: 118,
    avg_round: 26552,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.14,
    rank: 118,
    avg_round: 27890,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 118,
    avg_round: 26920,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 118,
    avg_round: 32880,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 128,
    avg_round: 33920,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 128,
    avg_round: 24600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.13,
    rank: 128,
    avg_round: 37152,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.13,
    rank: 128,
    avg_round: 29013,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 128,
    avg_round: 32007,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 128,
    avg_round: 28383,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 134,
    avg_round: 31876,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 134,
    avg_round: 35624,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 134,
    avg_round: 31450,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 134,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 134,
    avg_round: 37800,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 139,
    avg_round: 30480,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 139,
    avg_round: 34640,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.11,
    rank: 139,
    avg_round: 25830,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 139,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.11,
    rank: 139,
    avg_round: 35411,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 139,
    avg_round: 29080,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.11,
    rank: 139,
    avg_round: 27298,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.11,
    rank: 139,
    avg_round: 27105,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.11,
    rank: 139,
    avg_round: 23257,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 148,
    avg_round: 28206,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 148,
    avg_round: 31680,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 148,
    avg_round: 37208,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 148,
    avg_round: 26000,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 148,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.1,
    rank: 148,
    avg_round: 29800,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 148,
    avg_round: 36053,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.1,
    rank: 148,
    avg_round: 33840,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 156,
    avg_round: 34736,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 156,
    avg_round: 34020,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 156,
    avg_round: 29440,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 156,
    avg_round: 33507,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 156,
    avg_round: 24260,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 156,
    avg_round: 25260,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 156,
    avg_round: 35091,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.09,
    rank: 156,
    avg_round: 25480,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 156,
    avg_round: 26960,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'bailu',
    app_rate: 0.09,
    rank: 156,
    avg_round: 35570,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 156,
    avg_round: 35080,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 156,
    avg_round: 34747,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.09,
    rank: 156,
    avg_round: 38470,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 156,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 156,
    avg_round: 27080,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 156,
    avg_round: 26714,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 172,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 172,
    avg_round: 37947,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 172,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 172,
    avg_round: 24500,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 172,
    avg_round: 31376,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 172,
    avg_round: 34180,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 172,
    avg_round: 28840,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.08,
    rank: 172,
    avg_round: 25933,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 25640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 172,
    avg_round: 35200,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 172,
    avg_round: 29827,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 172,
    avg_round: 27693,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 172,
    avg_round: 37890,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.08,
    rank: 172,
    avg_round: 34680,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 172,
    avg_round: 26740,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 172,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 172,
    avg_round: 29875,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 172,
    avg_round: 27700,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 172,
    avg_round: 24610,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 172,
    avg_round: 25400,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 172,
    avg_round: 25272,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 172,
    avg_round: 30053,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 172,
    avg_round: 24520,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 28547,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 28373,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 172,
    avg_round: 29180,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 27760,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 199,
    avg_round: 23880,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 199,
    avg_round: 33392,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 199,
    avg_round: 32140,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 199,
    avg_round: 27000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 199,
    avg_round: 32560,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.07,
    rank: 199,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 199,
    avg_round: 39070,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 199,
    avg_round: 24640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.07,
    rank: 199,
    avg_round: 28200,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 199,
    avg_round: 24200,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 199,
    avg_round: 30336,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 199,
    avg_round: 25640,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 199,
    avg_round: 25147,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.07,
    rank: 199,
    avg_round: 37200,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 199,
    avg_round: 22200,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 199,
    avg_round: 29520,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 199,
    avg_round: 27320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 199,
    avg_round: 33680,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 199,
    avg_round: 31220,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 199,
    avg_round: 36040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 199,
    avg_round: 30293,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 199,
    avg_round: 25320,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 221,
    avg_round: 29080,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 221,
    avg_round: 34720,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 221,
    avg_round: 26547,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 221,
    avg_round: 39280,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 221,
    avg_round: 28700,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 221,
    avg_round: 26980,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'pela',
    app_rate: 0.06,
    rank: 221,
    avg_round: 34733,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 221,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 221,
    avg_round: 36213,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'march-7th',
    app_rate: 0.06,
    rank: 221,
    avg_round: 34040,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 221,
    avg_round: 26740,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 221,
    avg_round: 26736,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 221,
    avg_round: 30027,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 221,
    avg_round: 33220,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 221,
    avg_round: 34280,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 221,
    avg_round: 34040,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 221,
    avg_round: 37330,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 221,
    avg_round: 38040,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 221,
    avg_round: 29333,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 221,
    avg_round: 29900,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 221,
    avg_round: 31880,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'herta',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 221,
    avg_round: 23360,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'tingyun',
    app_rate: 0.06,
    rank: 221,
    avg_round: 36267,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lynx',
    app_rate: 0.06,
    rank: 221,
    avg_round: 32840,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 221,
    avg_round: 35240,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.06,
    rank: 221,
    avg_round: 36440,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 221,
    avg_round: 29400,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 221,
    avg_round: 26120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 221,
    avg_round: 33880,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 221,
    avg_round: 26650,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 221,
    avg_round: 28280,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 221,
    avg_round: 25220,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 221,
    avg_round: 28547,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'herta',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.06,
    rank: 221,
    avg_round: 37952,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 255,
    avg_round: 24387,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 255,
    avg_round: 36867,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 255,
    avg_round: 28270,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'yunli',
    char_three: 'herta',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 29947,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 30300,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 26120,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 255,
    avg_round: 31700,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 255,
    avg_round: 26530,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 255,
    avg_round: 37560,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.05,
    rank: 255,
    avg_round: 32370,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 28340,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 255,
    avg_round: 24880,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 255,
    avg_round: 29147,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 255,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 255,
    avg_round: 35920,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 255,
    avg_round: 21940,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 255,
    avg_round: 26800,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 255,
    avg_round: 25140,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 255,
    avg_round: 28853,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 24360,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 36024,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 255,
    avg_round: 34220,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 255,
    avg_round: 34980,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 255,
    avg_round: 32160,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 255,
    avg_round: 32120,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 255,
    avg_round: 29300,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 255,
    avg_round: 27976,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 28987,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 255,
    avg_round: 29680,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 255,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 25480,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 29940,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 255,
    avg_round: 26810,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 26360,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'clara',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 255,
    avg_round: 24070,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 255,
    avg_round: 26920,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 255,
    avg_round: 24493,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 255,
    avg_round: 37400,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 255,
    avg_round: 33693,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 255,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 255,
    avg_round: 33380,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 255,
    avg_round: 39940,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 255,
    avg_round: 35520,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 35227,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 299,
    avg_round: 35640,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 27380,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24240,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 25560,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24240,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 26420,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 27507,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 31310,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 32320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 32580,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 25720,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 299,
    avg_round: 25360,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 299,
    avg_round: 26120,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 29000,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24733,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.04,
    rank: 299,
    avg_round: 27840,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24060,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24740,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24380,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 36800,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.04,
    rank: 299,
    avg_round: 39200,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24360,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 32693,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 32280,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24720,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 29920,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 299,
    avg_round: 38040,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 23827,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 299,
    avg_round: 30180,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 31000,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 30700,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 299,
    avg_round: 25827,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 31740,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 25120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gepard',
    app_rate: 0.04,
    rank: 299,
    avg_round: 32280,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 299,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24300,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 27580,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 33253,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 25700,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 33653,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 21880,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 25400,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 346,
    avg_round: 35600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'herta',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 25600,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 28200,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 26400,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.03,
    rank: 346,
    avg_round: 34580,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 26380,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 38860,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 31380,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lynx',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24520,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 23080,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 26120,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 25640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 346,
    avg_round: 27360,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 32227,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 346,
    avg_round: 36560,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 346,
    avg_round: 27000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 32320,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 27053,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 346,
    avg_round: 26680,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 38040,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 23560,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 27200,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 26720,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 346,
    avg_round: 29720,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 32120,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 346,
    avg_round: 31320,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24740,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 27627,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24500,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24620,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 26640,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24400,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 32800,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 33467,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 20160,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 25820,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 35300,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24500,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 346,
    avg_round: 30360,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 346,
    avg_round: 33080,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 23307,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 25467,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 25840,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tingyun',
    app_rate: 0.03,
    rank: 346,
    avg_round: 33360,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 36680,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 22320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.03,
    rank: 346,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 21520,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 32600,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 346,
    avg_round: 35280,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 25720,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 23520,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'clara',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 23067,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 26980,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 26720,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24027,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 346,
    avg_round: 28947,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 22420,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'herta',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 30780,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 37060,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 27020,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 346,
    avg_round: 26680,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 346,
    avg_round: 25180,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'yunli',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 346,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 346,
    avg_round: 31300,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 346,
    avg_round: 28507,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 444,
    avg_round: 32460,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 34220,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 22660,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26260,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'bronya',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 444,
    avg_round: 28920,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27440,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 444,
    avg_round: 25840,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 444,
    avg_round: 32240,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 39480,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24280,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 25480,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27220,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27740,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 25340,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 31480,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27040,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24480,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 28880,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26200,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 444,
    avg_round: 31760,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26100,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27880,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 36600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 25400,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26340,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24720,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 444,
    avg_round: 25240,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24020,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 28320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 444,
    avg_round: 28960,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'herta',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 29460,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'clara',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 28980,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24280,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27720,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24760,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 30840,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 444,
    avg_round: 29320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'march-7th',
    app_rate: 0.02,
    rank: 444,
    avg_round: 35400,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26440,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 444,
    avg_round: 33500,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24220,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26680,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 444,
    avg_round: 25320,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 35280,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 444,
    avg_round: 38300,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 444,
    avg_round: 31820,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27760,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 444,
    avg_round: 31120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 30200,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26280,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24200,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24980,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26120,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27680,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 34440,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 25480,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 29240,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'yunli',
    char_three: 'herta',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 39360,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 444,
    avg_round: 33320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'serval',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.02,
    rank: 444,
    avg_round: 35040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 29240,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26800,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 32280,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'herta',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 444,
    avg_round: 29140,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 444,
    avg_round: 36480,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24100,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 444,
    avg_round: 23760,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27120,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 22940,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24360,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24440,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'himeko',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 444,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 444,
    avg_round: 29800,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26560,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 25860,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 31520,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24740,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'bronya',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 33520,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 25800,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26060,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27240,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26680,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 32440,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26080,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'march-7th',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 32240,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26480,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26160,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'herta',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 444,
    avg_round: 33920,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 29000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 25880,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 444,
    avg_round: 32640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'clara',
    char_three: 'herta',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.02,
    rank: 444,
    avg_round: 30320,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 29600,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 30880,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 30260,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 29640,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 21600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 28680,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 35300,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26780,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'clara',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 23520,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 31860,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27580,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 444,
    avg_round: 23360,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 25600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24760,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 29460,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'herta',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 28860,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 444,
    avg_round: 24640,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'himeko',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 29000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'jiaoqiu',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 37240,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'himeko',
    char_four: 'herta',
    app_rate: 0.02,
    rank: 444,
    avg_round: 30600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 27940,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 444,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 444,
    avg_round: 28400,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 30080,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 444,
    avg_round: 31280,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 444,
    avg_round: 26960,
    star_num: '4'
  }
];
