export const PFCurrentStage4Side1 = [
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 4.39,
    rank: 1,
    avg_round: 28141,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 4.08,
    rank: 2,
    avg_round: 32378,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 3.64,
    rank: 3,
    avg_round: 39904,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 3.42,
    rank: 4,
    avg_round: 35780,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'aventurine',
    app_rate: 2.57,
    rank: 5,
    avg_round: 30626,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 2.51,
    rank: 6,
    avg_round: 28330,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 2.11,
    rank: 7,
    avg_round: 35178,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 2.05,
    rank: 8,
    avg_round: 32227,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 2.01,
    rank: 9,
    avg_round: 39241,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 1.98,
    rank: 10,
    avg_round: 33084,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 1.85,
    rank: 11,
    avg_round: 38778,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 1.37,
    rank: 12,
    avg_round: 29226,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 1.32,
    rank: 13,
    avg_round: 39688,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 1.16,
    rank: 14,
    avg_round: 27716,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'huohuo',
    app_rate: 0.89,
    rank: 15,
    avg_round: 25189,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.87,
    rank: 16,
    avg_round: 36046,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.84,
    rank: 17,
    avg_round: 30512,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.83,
    rank: 18,
    avg_round: 23448,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.82,
    rank: 19,
    avg_round: 30703,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gallagher',
    app_rate: 0.82,
    rank: 19,
    avg_round: 27983,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.79,
    rank: 21,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.78,
    rank: 22,
    avg_round: 33182,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.73,
    rank: 23,
    avg_round: 20707,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.72,
    rank: 24,
    avg_round: 35433,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.72,
    rank: 24,
    avg_round: 25513,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.65,
    rank: 26,
    avg_round: 39840,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.65,
    rank: 26,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.64,
    rank: 28,
    avg_round: 38200,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.59,
    rank: 29,
    avg_round: 30606,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.59,
    rank: 29,
    avg_round: 33280,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.58,
    rank: 31,
    avg_round: 34991,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.57,
    rank: 32,
    avg_round: 27437,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.55,
    rank: 33,
    avg_round: 39467,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.53,
    rank: 34,
    avg_round: 31062,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.52,
    rank: 35,
    avg_round: 32432,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.5,
    rank: 36,
    avg_round: 30091,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.49,
    rank: 37,
    avg_round: 37160,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.48,
    rank: 38,
    avg_round: 39130,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.46,
    rank: 39,
    avg_round: 22640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.46,
    rank: 39,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.45,
    rank: 41,
    avg_round: 26704,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'lingsha',
    app_rate: 0.44,
    rank: 42,
    avg_round: 27431,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.44,
    rank: 42,
    avg_round: 38649,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.42,
    rank: 44,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.41,
    rank: 45,
    avg_round: 29811,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.4,
    rank: 46,
    avg_round: 25530,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.4,
    rank: 46,
    avg_round: 29686,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.4,
    rank: 46,
    avg_round: 39595,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.38,
    rank: 49,
    avg_round: 26406,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.37,
    rank: 50,
    avg_round: 27040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.35,
    rank: 51,
    avg_round: 39149,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.33,
    rank: 52,
    avg_round: 24320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.33,
    rank: 52,
    avg_round: 39975,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.33,
    rank: 52,
    avg_round: 37686,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.32,
    rank: 55,
    avg_round: 25120,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.32,
    rank: 55,
    avg_round: 27218,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.32,
    rank: 55,
    avg_round: 24606,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.32,
    rank: 55,
    avg_round: 27447,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.32,
    rank: 55,
    avg_round: 28608,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.31,
    rank: 60,
    avg_round: 27015,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.3,
    rank: 61,
    avg_round: 28280,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.29,
    rank: 62,
    avg_round: 36103,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.28,
    rank: 63,
    avg_round: 38710,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.28,
    rank: 63,
    avg_round: 39738,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'luocha',
    app_rate: 0.27,
    rank: 65,
    avg_round: 25538,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gepard',
    app_rate: 0.27,
    rank: 65,
    avg_round: 30100,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.26,
    rank: 67,
    avg_round: 34222,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.26,
    rank: 67,
    avg_round: 35640,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.26,
    rank: 67,
    avg_round: 39220,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.26,
    rank: 67,
    avg_round: 29573,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.25,
    rank: 71,
    avg_round: 26580,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.25,
    rank: 71,
    avg_round: 28640,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.24,
    rank: 73,
    avg_round: 24760,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.23,
    rank: 74,
    avg_round: 31596,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.23,
    rank: 74,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.23,
    rank: 74,
    avg_round: 33853,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.23,
    rank: 74,
    avg_round: 29640,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.23,
    rank: 74,
    avg_round: 24180,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.23,
    rank: 74,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 80,
    avg_round: 28369,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.22,
    rank: 80,
    avg_round: 30031,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 80,
    avg_round: 36460,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 80,
    avg_round: 28695,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.22,
    rank: 80,
    avg_round: 35213,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.21,
    rank: 85,
    avg_round: 36187,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.21,
    rank: 85,
    avg_round: 36864,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.21,
    rank: 85,
    avg_round: 28768,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.21,
    rank: 85,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.2,
    rank: 89,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.2,
    rank: 89,
    avg_round: 34973,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.2,
    rank: 89,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.19,
    rank: 92,
    avg_round: 39405,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.19,
    rank: 92,
    avg_round: 27331,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.19,
    rank: 92,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.19,
    rank: 92,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.19,
    rank: 92,
    avg_round: 38655,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.18,
    rank: 97,
    avg_round: 36747,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.18,
    rank: 97,
    avg_round: 38151,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.18,
    rank: 97,
    avg_round: 35623,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.18,
    rank: 97,
    avg_round: 29549,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.18,
    rank: 97,
    avg_round: 29563,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.18,
    rank: 97,
    avg_round: 24820,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.18,
    rank: 97,
    avg_round: 36012,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.17,
    rank: 104,
    avg_round: 36480,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.17,
    rank: 104,
    avg_round: 27749,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.17,
    rank: 104,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.16,
    rank: 107,
    avg_round: 39648,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 107,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'ruan-mei',
    app_rate: 0.16,
    rank: 107,
    avg_round: 26940,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 110,
    avg_round: 30880,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 110,
    avg_round: 24320,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.15,
    rank: 110,
    avg_round: 35960,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 110,
    avg_round: 24547,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.15,
    rank: 110,
    avg_round: 33284,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 110,
    avg_round: 27200,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 110,
    avg_round: 24590,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 110,
    avg_round: 31240,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.14,
    rank: 118,
    avg_round: 34263,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 118,
    avg_round: 38150,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 118,
    avg_round: 38231,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 118,
    avg_round: 33926,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 118,
    avg_round: 27600,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 118,
    avg_round: 33920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 124,
    avg_round: 30780,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 124,
    avg_round: 36827,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 124,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 124,
    avg_round: 34287,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 124,
    avg_round: 33810,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.13,
    rank: 124,
    avg_round: 37030,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 124,
    avg_round: 24246,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.12,
    rank: 131,
    avg_round: 29768,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 131,
    avg_round: 24980,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 131,
    avg_round: 34260,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 131,
    avg_round: 25010,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 131,
    avg_round: 26107,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 131,
    avg_round: 25680,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 131,
    avg_round: 25320,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 131,
    avg_round: 22980,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 131,
    avg_round: 38647,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 131,
    avg_round: 31627,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 131,
    avg_round: 27232,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 131,
    avg_round: 28970,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 131,
    avg_round: 39936,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 131,
    avg_round: 38189,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.11,
    rank: 145,
    avg_round: 24420,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 145,
    avg_round: 31680,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 145,
    avg_round: 30600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 145,
    avg_round: 38640,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 145,
    avg_round: 24280,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.11,
    rank: 145,
    avg_round: 25480,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 145,
    avg_round: 37446,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 145,
    avg_round: 29427,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.11,
    rank: 145,
    avg_round: 36280,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 145,
    avg_round: 29688,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.11,
    rank: 145,
    avg_round: 24620,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 145,
    avg_round: 37560,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 145,
    avg_round: 33867,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 158,
    avg_round: 30185,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 158,
    avg_round: 32293,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 158,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 158,
    avg_round: 34915,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 158,
    avg_round: 29270,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 158,
    avg_round: 28730,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 164,
    avg_round: 39000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 164,
    avg_round: 33573,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 164,
    avg_round: 27208,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 164,
    avg_round: 35053,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 164,
    avg_round: 33640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 164,
    avg_round: 38265,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 164,
    avg_round: 26640,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 164,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 164,
    avg_round: 26520,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 164,
    avg_round: 24149,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 164,
    avg_round: 24467,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 164,
    avg_round: 33920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 164,
    avg_round: 34806,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 164,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 164,
    avg_round: 35250,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.09,
    rank: 164,
    avg_round: 25380,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 164,
    avg_round: 25013,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 181,
    avg_round: 24560,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 181,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.08,
    rank: 181,
    avg_round: 33620,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 181,
    avg_round: 30660,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 181,
    avg_round: 30900,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 181,
    avg_round: 31920,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 181,
    avg_round: 28200,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 181,
    avg_round: 32900,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 181,
    avg_round: 39856,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.08,
    rank: 181,
    avg_round: 27400,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 181,
    avg_round: 28200,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 181,
    avg_round: 35987,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 181,
    avg_round: 36624,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 181,
    avg_round: 29340,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 181,
    avg_round: 25700,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.08,
    rank: 181,
    avg_round: 34656,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 181,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 181,
    avg_round: 30307,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 181,
    avg_round: 37900,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 181,
    avg_round: 25440,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.08,
    rank: 181,
    avg_round: 36180,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 181,
    avg_round: 25107,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 181,
    avg_round: 30147,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.08,
    rank: 181,
    avg_round: 33240,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 181,
    avg_round: 26307,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 181,
    avg_round: 34160,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 181,
    avg_round: 31560,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 181,
    avg_round: 27232,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.07,
    rank: 209,
    avg_round: 37800,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 209,
    avg_round: 31000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.07,
    rank: 209,
    avg_round: 27350,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 209,
    avg_round: 28920,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 209,
    avg_round: 36760,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'march-7th',
    app_rate: 0.07,
    rank: 209,
    avg_round: 36533,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 209,
    avg_round: 34620,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 209,
    avg_round: 28360,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 209,
    avg_round: 27213,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 209,
    avg_round: 37000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.07,
    rank: 209,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 209,
    avg_round: 34050,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 209,
    avg_round: 24380,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 209,
    avg_round: 29987,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 209,
    avg_round: 37440,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 209,
    avg_round: 36410,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 209,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.07,
    rank: 209,
    avg_round: 25000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 209,
    avg_round: 22820,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 228,
    avg_round: 23987,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 228,
    avg_round: 22240,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 228,
    avg_round: 32200,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.06,
    rank: 228,
    avg_round: 39040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.06,
    rank: 228,
    avg_round: 34640,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 228,
    avg_round: 28013,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 228,
    avg_round: 32900,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 228,
    avg_round: 32940,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 228,
    avg_round: 37780,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 228,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 228,
    avg_round: 39760,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'bailu',
    app_rate: 0.06,
    rank: 228,
    avg_round: 23240,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'welt',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 228,
    avg_round: 23728,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.06,
    rank: 228,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 228,
    avg_round: 39520,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 228,
    avg_round: 26707,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 228,
    avg_round: 24200,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 228,
    avg_round: 38700,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 228,
    avg_round: 38740,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 228,
    avg_round: 32760,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 228,
    avg_round: 37680,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 228,
    avg_round: 38973,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 228,
    avg_round: 27840,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 228,
    avg_round: 36320,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 228,
    avg_round: 24240,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 228,
    avg_round: 30300,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 228,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 228,
    avg_round: 36328,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 228,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 228,
    avg_round: 25920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 228,
    avg_round: 30270,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 228,
    avg_round: 38813,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 228,
    avg_round: 33856,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 228,
    avg_round: 39667,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 228,
    avg_round: 32480,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 263,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 263,
    avg_round: 37620,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 263,
    avg_round: 24600,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'kafka',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 263,
    avg_round: 33920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 263,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 263,
    avg_round: 31440,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 263,
    avg_round: 27660,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 263,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 263,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 263,
    avg_round: 26867,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 263,
    avg_round: 28440,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 263,
    avg_round: 38140,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.05,
    rank: 263,
    avg_round: 28384,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 263,
    avg_round: 25140,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 263,
    avg_round: 34573,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 263,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.05,
    rank: 263,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 263,
    avg_round: 39960,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 263,
    avg_round: 28840,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 263,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 263,
    avg_round: 26320,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'welt',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 263,
    avg_round: 25440,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 263,
    avg_round: 30040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.05,
    rank: 263,
    avg_round: 33560,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 263,
    avg_round: 36330,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 263,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 263,
    avg_round: 37080,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 263,
    avg_round: 37880,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.05,
    rank: 263,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 263,
    avg_round: 39820,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 263,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 263,
    avg_round: 32253,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 263,
    avg_round: 25680,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 263,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'kafka',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 263,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 263,
    avg_round: 35400,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 33580,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 31960,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 37860,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 30180,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 299,
    avg_round: 37120,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 27260,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 31680,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'bailu',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 35760,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'march-7th',
    app_rate: 0.04,
    rank: 299,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 38813,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 25627,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 30460,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 299,
    avg_round: 39640,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 32680,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 38440,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 28960,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 27253,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 299,
    avg_round: 20000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 39560,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'trailblazer-remembrance',
    char_four: 'pela',
    app_rate: 0.04,
    rank: 299,
    avg_round: 31973,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 299,
    avg_round: 37120,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 25400,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 37693,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 39820,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'silver-wolf',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 31040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 38780,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 26520,
    star_num: '4'
  },
  {
    char_one: 'robin',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 28560,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 31920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 299,
    avg_round: 26880,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 37700,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 299,
    avg_round: 34280,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 29800,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 299,
    avg_round: 37260,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 34440,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 299,
    avg_round: 34427,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 28000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 299,
    avg_round: 26160,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 30400,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 299,
    avg_round: 27160,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 34960,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 27400,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 299,
    avg_round: 39640,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 299,
    avg_round: 30440,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.04,
    rank: 299,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 299,
    avg_round: 24820,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 37400,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'yunli',
    char_three: 'tingyun-fugue',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 361,
    avg_round: 30400,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 24280,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 21800,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 30680,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 31560,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 24660,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 361,
    avg_round: 34100,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 361,
    avg_round: 37020,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 361,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 24200,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 25400,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 30800,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 22000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 361,
    avg_round: 33773,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 35340,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 21560,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 361,
    avg_round: 37280,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 361,
    avg_round: 26400,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 25960,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 361,
    avg_round: 35800,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 361,
    avg_round: 31840,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 361,
    avg_round: 33320,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 361,
    avg_round: 28440,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.03,
    rank: 361,
    avg_round: 26080,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 37400,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 361,
    avg_round: 38653,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'kafka',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 30307,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'march-7th',
    app_rate: 0.03,
    rank: 361,
    avg_round: 28060,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 361,
    avg_round: 26020,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 25420,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 35480,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 361,
    avg_round: 39200,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 27040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'trailblazer-preservation',
    app_rate: 0.03,
    rank: 361,
    avg_round: 37960,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 24840,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 37280,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 35180,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 26920,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 23680,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 361,
    avg_round: 38920,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 361,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 27120,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 29960,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 24720,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'acheron',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 361,
    avg_round: 34240,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 361,
    avg_round: 36440,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 361,
    avg_round: 27960,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 32040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 361,
    avg_round: 38260,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 26093,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 27580,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 33293,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 361,
    avg_round: 27360,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 361,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'march-7th',
    app_rate: 0.03,
    rank: 361,
    avg_round: 31320,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 361,
    avg_round: 27720,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24600,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'acheron',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'acheron',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'kafka',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 28640,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 38880,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 23560,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'serval',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 33480,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 20240,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 35560,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 37640,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 36320,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 451,
    avg_round: 26680,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 30760,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 451,
    avg_round: 23780,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 31640,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sampo',
    char_three: 'pela',
    char_four: 'march-7th',
    app_rate: 0.02,
    rank: 451,
    avg_round: 29280,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 26720,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 30760,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 23000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 29920,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'pela',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 28040,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'serval',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 29020,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 451,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 32440,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24920,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 31680,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'ruan-mei',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 39440,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 451,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 33600,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 39160,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 29720,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 27600,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24200,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 26720,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24880,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 451,
    avg_round: 33640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 32160,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 27120,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'welt',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24740,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'welt',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 26840,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 28920,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 22000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 23200,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 451,
    avg_round: 29640,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 30800,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 25320,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 32620,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 451,
    avg_round: 27280,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 20200,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24240,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'black-swan',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24400,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'welt',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 38460,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 28760,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 451,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'welt',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 22800,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 25500,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'aventurine',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'bronya',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 39040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 451,
    avg_round: 32920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'acheron',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 31640,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24320,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 28880,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'clara',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 27960,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 451,
    avg_round: 30720,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 27420,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 28160,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 38800,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 23880,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 34220,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 21120,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24880,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 26800,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 29520,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 36380,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'jade',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 25400,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 33120,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 23040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 38280,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 37300,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 35800,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 25380,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 25560,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 32720,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 451,
    avg_round: 30800,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'serval',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'bronya',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'acheron',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'acheron',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 451,
    avg_round: 0,
    star_num: '4'
  }
];
